import {
  REEL_LIST_FAIL,
  REEL_LIST_REQUEST,
  REEL_LIST_SUCCESS,
} from "../constants/reelConstants";

//REEL LIST REDUCER
export const reelListReducer = (state = { reels: [] }, action) => {
  switch (action.type) {
    case REEL_LIST_REQUEST:
      return { loading: true, reels: [] };
    case REEL_LIST_SUCCESS:
      return {
        loading: false,
        reels: action.payload.data,
      };
    case REEL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
